@import "~bootstrap/scss/functions.scss";
@import "~bootstrap/scss/variables.scss";
@import "~bootstrap/scss/mixins.scss";

@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

$font-sans-serif: "Inter var", sans-serif;
$font-family-base: $font-sans-serif;

$enable-negative-margins: true;
$body-color: $light;
$body-bg: rgba(44, 44, 44, 1);
$card-bg: rgba(24, 24, 24, 1);

$modal-content-bg: $card-bg;
$modal-header-border-color: lighten($card-bg, 5%);
$modal-footer-border-color: $modal-header-border-color;

$input-color: white;
$input-focus-color: white;
$input-bg: lighten($card-bg, 10%);
$input-focus-bg: lighten($input-bg, 10%);
$input-border-color: lighten($input-bg, 10%);
$input-focus-border-color: lighten($input-bg, 10%);

$form-select-color: $input-color;
$form-select-bg: $input-bg;
$form-select-border-color: $input-border-color;
$form-select-focus-border-color: $input-border-color;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": rgba(82, 196, 26, 1),
  "error": rgba(255, 77, 79, 1),
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$card-border-width: 0;

#root {
  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  /* redefine theme color rgb vars (used for bg- colors) */
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};
}

@for $i from 1 through 9 {
  .opacity-#{$i * 10} {
    opacity: #{calc($i / 10)};
  }
}

@for $i from 1 through 8 {
  .fw-#{$i * 100} {
    font-weight: #{$i * 100};
  }
}

@for $i from 1 through 3 {
  .rotate-#{$i * 90} {
    transform: rotate(#{$i * 90}deg);
  }
}

$font-sizes-px: 32, 24, 20, 18, 16, 14, 12, 10;

@each $size in $font-sizes-px {
  .fs-#{$size} {
    font-size: #{$size}px !important;
  }
}

.vertical-separator {
  content: "";
  min-width: 1px;
  min-height: 100%;
  box-shadow: 1.5px 0 0 rgb(72 72 73);
}

.horizontal-separator {
  content: "";
  min-height: 1px;
  min-width: 100%;
  box-shadow: 0 1.5px 0 rgb(72 72 73);
}

a,
.btn.btn-link {
  color: inherit !important;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.btn.btn-nav {
  opacity: 70%;
  border: 0 !important;
  border-radius: 0;
  border-bottom: 3px solid transparent !important;
  &:hover {
    opacity: 100%;
  }
  &.active {
    opacity: 100%;
    border-bottom: 3px solid white !important;
  }
}

.border-card {
  border: 1px solid rgba(72, 72, 73, 1);
}

.btn.btn-bordered {
  border-width: 1px;
  border-color: white;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.tooltip {
  position: absolute;
}

table {
  & > thead {
    border-bottom: 1px solid rgb(72, 72, 73);
    & > tr > th {
      opacity: 70%;
    }
  }

  & > tbody > tr > td,
  & > thead > tr > th {
    font-weight: 400;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
  }
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.8 !important;
}

select.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
}

.logo-font {
  font-family: "Rubik";
}

div[class^="_notification_"] {
  display: none !important;
}

.custom-leanspace-widget {
  .uplot {
    min-height: 500px;
  }
  :global {
    .u-legend {
      color: "#fff";
    }
  }
}

@import "~bootstrap/scss/bootstrap.scss";
